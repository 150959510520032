<template>
  <component :is="materialData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="materialData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching material data
      </h4>
      <div class="alert-body">
        No material found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-materials-list'}"
        >
          Materials
        </b-link>
        list.
      </div>
    </b-alert>

    <b-button
      variant="primary"
      class="m-1"
      size="sm"
      @click="goBack"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
    </b-button>

    <b-tabs
      v-if="materialData"
      pills
    >

      <!-- Tab: Basic -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="ImageIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Basic</span>
        </template>
        <material-edit-tab-basic
          :material-data="materialData"
          :material-category-type-options="materialCategoryTypeOptions"
          :material-unit-options="materialUnitOptions"
          :material-fabric-weight-options="materialFabricWeightOptions"
          :material-quality-options="materialQualityOptions"
          :material-fabric-finishing-options="materialFabricFinishingOptions"
          :color-options="colorOptions"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BButton,
} from 'bootstrap-vue'
import {
  computed, ref, onMounted, onUnmounted,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import materialStoreModule from '../materialStoreModule'
import MaterialEditTabBasic from './MaterialEditTabBasic.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,

    MaterialEditTabBasic,
  },
  setup(props, { root }) {
    const materialData = ref(null)

    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
      }
    })

    const materialCategoryTypeOptions = computed(() => {
      const options = root.$store.getters['app-material/materialCategoryTypeOptions']
      return options
    })
    const materialUnitOptions = computed(() => {
      const options = root.$store.getters['app-material/materialUnitOptions']
      return options
    })
    const materialFabricWeightOptions = computed(() => {
      const options = root.$store.getters['app-material/materialFabricWeightOptions']
      return options
    })
    const materialQualityOptions = computed(() => {
      const options = root.$store.getters['app-material/materialQualityOptions']
      return options
    })
    const materialFabricFinishingOptions = computed(() => {
      const options = root.$store.getters['app-material/materialFabricFinishingOptions']
      return options
    })
    const colorOptions = computed(() => {
      const options = root.$store.getters['app-material/colorOptions']
      return options
    })

    const optionsInit = () => {
      root.$store.dispatch('app-material/fetchFormOptions')
    }

    const fetchStyle = () => {
      store.dispatch('app-material/fetchMaterial', { id: router.currentRoute.params.id })
        .then(response => { materialData.value = response.data })
        .catch(error => {
          if (error.response.status === 404) {
            materialData.value = undefined
          }
        })
    }

    const goBack = () => {
      router.go(-1)
    }

    onMounted(optionsInit)
    onMounted(fetchStyle)

    return {
      materialData,

      materialCategoryTypeOptions,
      materialUnitOptions,
      materialFabricWeightOptions,
      materialQualityOptions,
      materialFabricFinishingOptions,
      colorOptions,
      goBack,
    }
  },
}
</script>

<style>

</style>
