<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{invalid}"
    >
      <!-- Style Info: Input Fields -->
      <b-form
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Image -->
          <b-col>
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="128"
                  :src="formData.image"
                />
              </template>
            </b-media>

            <b-form-group
              label="Image"
              label-for="image"
            >
              <b-form-file
                v-model="image"
                placeholder="Choose file or drop it here..."
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Category Type -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Category Type"
              label-for="category-type"
            >
              <v-select
                v-model="formData.category_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="materialCategoryTypeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="category-type"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Ref -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Ref"
              rules="required"
            >
              <b-form-group
                label="ref"
                label-for="ref"
              >
                <b-form-input
                  id="ref"
                  v-model="formData.ref"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <template v-if="formData.category_type === 'Fabric'">
          <b-row>
            <!-- Field: Fabric Weight -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Fabric Weight"
                label-for="material-fabric-weight"
              >
                <v-select
                  v-model="formData.fabric_weight"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="materialFabricWeightOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="material-fabric-weight"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Quality -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Quality"
                label-for="material-quality"
              >
                <v-select
                  v-model="formData.quality"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="materialQualityOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="material-quality"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Fabric Finishing -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Fabric Finishing"
                label-for="material-fabric-finishing"
              >
                <v-select
                  v-model="formData.fabric_finishing"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="materialFabricFinishingOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="material-fabric-finishing"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- Field: Composition -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Composition"
                label-for="composition"
              >
                <b-form-textarea
                  id="composition"
                  v-model="formData.composition"
                  trim
                  rows="3"
                  max-rows="5"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Useful Width -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Useful Width"
                label-for="useful-width"
              >
                <b-form-textarea
                  id="useful-width"
                  v-model="formData.useful_width"
                  trim
                  rows="3"
                  max-rows="5"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <b-row>
          <!-- Field: Unit -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Unit"
              label-for="material-unit"
            >
              <v-select
                v-model="formData.unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="materialUnitOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="material-unit"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Supplier -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Supplier"
              label-for="supplier"
            >
              <b-form-input
                id="supplier"
                v-model="formData.supplier"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Sizes -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Sizes"
              label-for="material-sizes"
            >
              <b-form-tags
                v-model="formData.sizes"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="material-sizes"
                placeholder="Enter a new size by pressing Enter"
                duplicate-tag-text="Duplicated size"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Colors -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Colors"
              label-for="colors"
            >
              <v-select
                v-model="formData.colors"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="colorOptions"
                :reduce="val => val.value"
                multiple
                :clearable="false"
                input-id="colors"
              >
                <template v-slot:option="option">
                  <span
                    :style="{backgroundColor: option.color,
                             marginRight: '5px', width: '20px', height:'20px',
                             borderRadius: '4px', display:'inline-block'}"
                  /><span>{{ option.label }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Reset
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BAvatar, BButton, BRow, BCol, BFormFile, BFormGroup, BFormInput, BForm,
  BFormInvalidFeedback, BFormTags, BFormTextarea, BMedia,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormTags,
    BFormTextarea,
    BMedia,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    materialData: {
      type: Object,
      required: true,
    },
    materialCategoryTypeOptions: {
      type: Array,
      required: true,
    },
    materialUnitOptions: {
      type: Array,
      required: true,
    },
    materialFabricWeightOptions: {
      type: Array,
      required: true,
    },
    materialQualityOptions: {
      type: Array,
      required: true,
    },
    materialFabricFinishingOptions: {
      type: Array,
      required: true,
    },
    colorOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props) {
    const toast = useToast()

    const image = ref(null)
    const formData = ref({ ...props.materialData })
    formData.value.colors = formData.value.colors.map(color => color.id)

    const onSubmit = () => {
      const formFields = ['ref',
        'supplier', 'category_type', 'unit', 'fabric_weight', 'quality',
        'composition', 'useful_width']

      const materialFormData = new FormData()
      materialFormData.append('id', router.currentRoute.params.id)

      if (formData.value.fabric_finishing) {
        formData.value.fabric_finishing.forEach(val => {
          materialFormData.append('fabric_finishing', val)
        })
      }
      if (image.value) {
        materialFormData.append('image', image.value)
      }
      formFields.forEach(
        key => materialFormData.append(key, formData.value[key]),
      )
      if (formData.value.sizes) {
        materialFormData.append('sizes', JSON.stringify(formData.value.sizes))
      }
      materialFormData.append('color_ids', formData.value.colors || '')
      store.dispatch('app-material/editMaterial', materialFormData)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Material edited',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'apps-materials-list' })
        })
    }

    const resetFormData = () => {
      formData.value = { ...props.materialData }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    return {
      onSubmit,
      formData,
      image,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
